import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "@components/layout";
import Seo from "@components/seo";

import TopNav from "@components/topNav";
import Footer from "@components/footer";

import { IoPlay, IoPause } from "react-icons/io5";

const people = [
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
];

const trustees = [
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.  Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.  Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.  Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.  Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Name surname",
    role: "Job title",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum. Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.  Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
];

const PeoplePage = () => {
  return (
    <div>
      <div className="relative bg-lime overflow-hidden">
        <div className="max-w-6xl mx-auto">
          <div className="relative z-10 pb-8 bg-lime sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidde pl-20 lg:block absolute right-0 inset-y-0 h-full w-56 text-lime transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 180 585"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="Path"
                  fill="#A5CF07"
                  fillRule="nonzero"
                  points="0.296038107 585 58.6111495 585 179.266137 296.235761 60.0246807 0 0.296038107 0"
                ></polygon>
              </g>
            </svg>

            <TopNav theme={"sunset"} />

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left pb-10">
                <h1 className="text-4xl tracking-loose font-bold text-marineDark sm:text-5xl md:text-5xl font-heading">
                  Our people
                  {/* <span className="block text-indigo-600 xl:inline"></span> */}
                </h1>
                <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Our trustees and team bring together a diverse mix of
                  expertise, basically I want to introduce how amazing you are,
                  here in this section.
                </p>

                {/* <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Avert is using the power of digital communications to increase
                knowledge and understanding of HIV and sexual health, so people
                can make informed choices and live healthily.
              </p> */}
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/hero-people-flip.jpg"
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="Avert"
            placeholder="blurred"
            layout="fullWidth"
            // width={2000}
            // height={1333}
          />
        </div>
      </div>
      {/* <div className="relative bg-gray-50 pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8">
        <div className="relative max-w-7xl mx-auto"></div>
      </div> */}

      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-8">
          <div>
            <h2 className="text-3xl font-extrabold tracking-normal sm:text-4xl pt-3 pb-9 font-heading">
              Meet our team
            </h2>
          </div>

          <ul
            role="list"
            className="space-y-12 grid-cols  lg:grid lg:grid-cols-3 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
          >
            {people.map((person, index) => (
              <li key={index}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img
                      className="object-cover shadow-lg rounded-lg"
                      src={person.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="text-md leading-6 font-medium space-y-1">
                    <h3>{person.name}</h3>
                    <p className="text-lime">{person.role}</p>
                  </div>
                  <div className="text-sm">
                    <p className="text-gray-500">{person.bio}</p>
                  </div>

                  <ul role="list" className="flex space-x-5">
                    <li>
                      <a
                        href={person.linkedinUrl}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="bg-marineDark">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-8">
          <div>
            <h2 className=" text-3xl font-extrabold tracking-normal sm:text-4xl pt-3 pb-9 text-lime font-heading">
              Meet our Trustees
            </h2>
          </div>
          <div>
            <div className="text-white">
              <ul
                role="list"
                className="space-y-12 grid-cols  lg:grid lg:grid-cols-3 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
              >
                <li key={1}>
                  <div className="space-y-4">
                    <div className="aspect-w-3 aspect-h-2">
                      {/* <img
                          className="object-cover shadow-lg rounded-lg"
                          src={person.imageUrl}
                          alt=""
                        /> */}
                      <StaticImage
                        src="../images/trustees/anna.jpg"
                        className="object-cover shadow-lg rounded-lg"
                        alt="Avert"
                        placeholder="blurred"
                        width={1024}
                        height={1024}
                      />
                    </div>
                    <div className="text-md leading-6 font-medium space-y-2">
                      <h3>Anna Becker</h3>
                      <p className="text-lime">Trustee</p>
                    </div>
                    <div className="text-sm">
                      <p className="text-white pb-2">
                        Anna has over 15 years’ experience developing donor
                        engagement strategies, successful funding proposals, and
                        managing strategic relationships with institutional
                        funders.
                      </p>
                      <p className="text-white pb-2">
                        She is currently the Director of Institutional Funding
                        at Sightsavers and plays a role in overall
                        organisational management through membership of the
                        Management Team.{" "}
                      </p>
                      <p className="text-white pb-2">
                        She has previously worked with Frontline AIDS and is
                        excited about joining the Avert board and continuing to
                        support the HIV/AIDS response.
                      </p>
                    </div>
                  </div>
                </li>
                {trustees.map((person, index) => (
                  <li key={index}>
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img
                          className="object-cover shadow-lg rounded-lg"
                          src={person.imageUrl}
                          alt=""
                        />
                        {/* <StaticImage
                          src="../images/trustees/anna.jpg"
                          className="object-cover shadow-lg rounded-lg"
                          alt="Avert"
                          placeholder="blurred"
                        /> */}
                      </div>
                      <div className="text-md leading-6 font-medium space-y-1">
                        <h3>{person.name}</h3>
                        <p className="text-lime">{person.role}</p>
                      </div>
                      <div className="text-sm">
                        <p className="text-white pb-2">
                          Anna has over 15 years’ experience developing donor
                          engagement strategies, successful funding proposals,
                          and managing strategic relationships with
                          institutional funders.
                        </p>
                        <p className="text-white pb-2">
                          She is currently the Director of Institutional Funding
                          at Sightsavers and plays a role in overall
                          organisational management through membership of the
                          Management Team.{" "}
                        </p>
                        <p className="text-white pb-2">
                          She has previously worked with Frontline AIDS and is
                          excited about joining the Avert board and continuing
                          to support the HIV/AIDS response.
                        </p>
                      </div>
                      {/* <div className="text-sm">
                        <p className="text-white">{person.bio}</p>
                      </div> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Partners */}
      <div className="bg-lime">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <p className="text-center font-bold  text-white tracking-wide text-2xl font-heading">
            Partner with us
          </p>
          <p className="text-center text-white tracking-wide text-l pt-2 font-body">
            We work with a wide range of partners and are keen to increase our
            engagement with others
          </p>

          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-marineDark hover:bg-marine"
              >
                Find out more
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PeoplePage;
